/** Custome Elements */
import './custom-elements/vi-cart.js';
import './custom-elements/vi-carousel.js';
import './custom-elements/vi-dialog.js';
import './custom-elements/vi-form.js';
import './custom-elements/vi-input.js';
import './custom-elements/vi-product-list-item.js';
import './custom-elements/vi-related-pages.js';
import './custom-elements/vi-toast.js';
import './custom-elements/vi-tooltip.js';
import './custom-elements/vi-wishlist.js';

/** Atoms */
import './components/a-lang-switch.js';
import './components/a-input.js';
import './components/a-field.js';

/** Molecules */
import './components/m-announcement.js';
import './components/m-drawer-cart.js';
import './components/m-drawer-wishlist.js';
import './components/m-filter-catalog.js';
import './components/m-navigation-flyout.js';
import './components/m-navigation.js';
import './components/m-pcon-configurator.js';
import './components/m-product-type.js';
import './components/m-search.js';

/** Organisms */
import './components/o-checkout.js';
import './components/o-footer.js';
import './components/o-order.js';
import './components/o-roomle-configurator.js';
import './components/o-wishlist.js';

import translations from './foundation/translations.js';

const language = document.documentElement.lang;
window.translations = translations[language] ?? translations.en;
