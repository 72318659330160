const element = document.querySelector('.m-announcement');

function setCSSVariable() {
	document.documentElement.style.setProperty('--block-size-announcement', `${element.offsetHeight - 8}px`);

	// Triggers layout paint – fix for Safari
	const linkElement = element.querySelector(':scope > *');
	linkElement.style.width = `${linkElement.offsetWidth + 1}px`;

	requestAnimationFrame(() => {
		requestAnimationFrame(() => {
			linkElement.style.width = null;
			document.documentElement.style.setProperty('--block-size-announcement', `${element.offsetHeight - 8}px`);
		});
	});
}

if (element) {
	window.addEventListener('resize', setCSSVariable);
	window.addEventListener('load', setCSSVariable);
	setCSSVariable();
}
