/* global translations */

import {
	html,
	render,
} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

const loadingIconInStack = `<div class="m-stack" data-justify="center"><span class="a-icon" data-kind="loader" data-size="large">${icons.loader}</span></div>`;

class OOrder {
	apiUrl = '/api/cart';

	/** @type object */
	data;

	constructor(element) {
		this.cartElement = element.querySelector('.o-order__cart');
		this.orderId = element.dataset.orderId;

		const buttonPrintElement = element.querySelector('[data-action="print"]');
		buttonPrintElement?.addEventListener('click', () => {
			window.print();
		});

		this.fetchProductList();
	}

	get items() {
		return this.data?.items ?? [];
	}

	get sum() {
		return this.data?.sum;
	}

	async fetchProductList() {
		const {
			apiUrl,
			orderId,
		} = this;

		const url = new URL(`${apiUrl}?order=${orderId}&view=order`, window.location.origin);

		const responseData = await fetch(url, {
			method: 'get',
			headers: {
				'x-language': document.documentElement.lang,
			},
		})
			.then((response) => response.json())
			.catch((error) => {
				// Handle any errors
				console.error(error);
			});

		if (responseData.status === 'ok') {
			this.data = responseData.data;
		} else {
			// Handle response errors
			alert(responseData?.message ?? 'Unkown error');
			console.error(responseData?.message ?? 'Unkown error');
		}

		this.renderCart();
	}

	renderCart() {
		const {
			items = [],
			data,
		} = this;

		render(html`
			${items.length === 0 ? unsafeHTML(loadingIconInStack) : ''}
			<div class="product-list">
				${items?.map((item) => html`
					<vi-product-list-item
						.readonly=${true}
						.key=${item.key}
						.productImage=${item.productImage}
						.url=${item.url}
						.productTitle=${item.title}
						.subtitle=${item.subtitle}
						.articleNumber=${item.articleNumber}
						.color=${item.color}
						.size=${item.size}
						.price=${item.priceNet}
						.sum=${item.sumNet}
						.quantity=${item.quantity}
						.length=${item.length}
						.option=${item.option}
						.maxAmount=${item.maxAmount}
						.system=${item.system}
						.configuration=${item.configuration}
						.packageContents=${item.packageContents}
					>
				`)}
			</div>
			<div class="footer">
				<table class="a-table-sum">
					<tbody>
						<tr><th>${translations['cart.subtotal']}</th><td>${data.subtotalNet}</td></tr>
						${data.discount ? html`
							<tr>
								<th>${translations['cart.discount']} ${data.discount.discount.toLocaleString()}% (${translations['field.discount_code']}: ${data.discount.discountCode})</th>
								<td>${data.discount.priceNet}</td>
							</tr>
						` : null}
						<tr>
							<th>
								${translations['cart.shipping']} (${data.shipping.type} ${data.shipping.country}${data.shipping.postalCode ? `, ${translations['field.postal_code']} ${data.shipping.postalCode}` : null})
							</th>
							<td>+ ${data.shipping.priceNet}</td>
						</tr>
					</tbody>
					<tbody data-kind="secondary">
						<tr><th>${translations['cart.net']}</th><td>${data.sumNet}</td></tr>
						${data.taxRates.map((taxRate) => html`
							<tr><th>${translations['cart.tax']} (${taxRate.taxRate}%)</th><td>+ ${taxRate.sum}</td></tr>
						`)}
					</tbody>
					<tfoot>
						<tr>
							<th>${translations['cart.sum']}</th>
							<td>${this.sum}</td>
						</tr>
					</tfoot>
				</table>
			</div>
		`, this.cartElement);
	}
}

const element = document.querySelector('.o-order');
if (element) {
	// eslint-disable-next-line no-new
	new OOrder(element);
}
