/* global Stripe */

/**
 * @typedef {import("../custom-elements/vi-cart").default} ViCart
 */

import {
	html,
	render,
} from 'lit';

const element = document.querySelector('.o-checkout');

// CONDITIONAL FIELDS
// Show/hide fields depending on other fields
// https://getkirby.com/docs/guide/blueprints/fields#conditional-fields
const checkboxElements = element?.querySelectorAll('input[type="checkbox"]');
const selectElements = element?.querySelectorAll('select');
const conditionalFieldElements = element?.querySelectorAll('.a-field[data-when], .a-text[data-when], hr[data-when]');

function updateRequiredField(fieldElement) {
	const inputElement = fieldElement.querySelector('input, select, textarea');
	if (inputElement) {
		if (fieldElement.hidden === true && inputElement.getAttribute('required') !== null) {
			inputElement.dataset.conditionalRequired = 'true';
			inputElement.removeAttribute('required');
		} else if (inputElement.dataset.conditionalRequired === 'true') {
			inputElement.setAttribute('required', '');
		}
	}
}

function updateConditionalFields(name, value) {
	conditionalFieldElements.forEach((conditionalFieldElement) => {
		const whenObject = JSON.parse(conditionalFieldElement.dataset.when);
		Object.keys(whenObject).forEach((key) => {
			if (name === key.toLowerCase()) {
				if (Array.isArray(whenObject[key])
					? whenObject[key].includes(value)
					: whenObject[key] === value) {
					conditionalFieldElement.removeAttribute('hidden');
				} else {
					conditionalFieldElement.setAttribute('hidden', '');
				}
				updateRequiredField(conditionalFieldElement);
			}
		});
	});
}

checkboxElements?.forEach((checkboxElement) => {
	checkboxElement.addEventListener('change', () => {
		updateConditionalFields(checkboxElement.name, checkboxElement.checked);
	});
	updateConditionalFields(checkboxElement.name, checkboxElement.checked);
});
selectElements?.forEach((selectElement) => {
	selectElement.addEventListener('change', () => {
		updateConditionalFields(selectElement.name, selectElement.value);
	});
	updateConditionalFields(selectElement.name, selectElement.value);
});

class OCheckout {
	stripe;

	stripeElements;

	#fetchControllerCountry = null;

	#fetchControllerPostalCode = null;

	constructor(element) {
		this.element = element;
		this.submitElement = element.querySelector('button[type="submit"]');
		this.discountCodeElement = element.querySelector('input[name="discount_code"]');
		this.addDiscountButtonElement = element.querySelector('button[name="add-discount"]');
		this.removeDiscountButtonElement = element.querySelector('button[name="remove-discount"]');
		this.checkoutDiscountFormElement = element.querySelector('#checkout-discount-form');
		this.checkoutFormElement = element.querySelector('#checkout-form');
		this.globalErrorElement = this.checkoutFormElement.querySelector('.m-form__error');
		this.deliveryTimeInfoElement = this.checkoutFormElement.querySelector('.a-text[data-name="delivery_time_info"]');

		/** @type {ViCart} */
		this.viCart = document.querySelector('vi-cart');

		this.#initStripe();
		this.#initEventListeners();
		this.#updateDiscount();
	}

	#initStripe() {
		const { element, submitElement } = this;

		// Init Stripe
		const stripePublishableKey = element.querySelector('input[name="stripePublishableKey"]')?.value ?? null;
		const stripeStyle = {
			base: {
				fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family-sans'),
				fontSize: '16px',
			},
		};

		// check if stripePublishableKey is null or empty
		if (stripePublishableKey) {
			this.stripe = Stripe(stripePublishableKey);
			this.stripeElements = this.stripe.elements({
				locale: document.querySelector('html').lang,
			});

			if (document.getElementById('stripe-card')) {
				// Create Stripe Card Element
				// https://stripe.com/docs/js/elements_object/create_element
				const stripeCardElement = this.stripeElements.create('card', {
					hidePostalCode: true,
					style: stripeStyle,
					disableLink: true,
				});
				// https://stripe.com/docs/js/element/mount
				stripeCardElement.mount('#stripe-card');
			}

			if (document.getElementById('stripe-iban')) {
				// Create Stripe IBAN Element
				// https://stripe.com/docs/js/elements_object/create_element
				const stripeIbanElement = this.stripeElements.create('iban', {
					supportedCountries: ['SEPA'],
					style: stripeStyle,
				});
				// https://stripe.com/docs/js/element/mount
				stripeIbanElement.mount('#stripe-iban');
			}
		}

		submitElement.dataset.defaultSubmitText = submitElement.innerText;
	}

	#initEventListeners() {
		const {
			viCart,
			element,
			submitElement,
			checkoutFormElement,
			checkoutDiscountFormElement,
		} = this;
		const paymentMethodInputElements = element.querySelectorAll('input[name="paymentMethod"]');
		const paymentMethodFieldElements = element.querySelectorAll('.a-field[data-payment-method]');

		// FUNCTIONS
		function updatePaymentMethods(paymentMethodInputElement) {
			const paymentMethod = paymentMethodInputElement.value;

			if (paymentMethodInputElement.dataset.submitText) {
				submitElement.innerText = paymentMethodInputElement.dataset.submitText;
			} else {
				submitElement.innerText = submitElement.dataset.defaultSubmitText;
			}

			paymentMethodFieldElements.forEach((paymentMethodFieldElement) => {
				if (paymentMethod === paymentMethodFieldElement.dataset.paymentMethod) {
					paymentMethodFieldElement.removeAttribute('hidden');
				} else {
					paymentMethodFieldElement.setAttribute('hidden', '');
				}
			});
		}

		paymentMethodInputElements.forEach((paymentMethodInputElement) => {
			if (paymentMethodInputElement.checked) {
				updatePaymentMethods(paymentMethodInputElement);
			}

			paymentMethodInputElement.addEventListener('change', (event) => {
				updatePaymentMethods(event.target);
			});
		});

		/** @type {HTMLSelectElement} */
		const countryElement = checkoutFormElement.elements.country;
		/** @type {HTMLInputElement} */
		const postalCodeElement = checkoutFormElement.elements.postal_code;
		/** @type {HTMLInputElement} */
		const shippingPostalCodeElement = checkoutFormElement.elements.shipping_postal_code;
		/** @type {HTMLInputElement} */
		const billingAddressIsShippingAddressElement = checkoutFormElement
			.elements.billingaddress_is_shippingaddress;

		checkoutFormElement.addEventListener('submit', this.onSubmit.bind(this));
		checkoutDiscountFormElement.addEventListener('submit', this.onDiscountSubmit.bind(this));

		countryElement.addEventListener('change', this.#onCountryElementChange.bind(this));
		postalCodeElement.addEventListener('input', this.#updatePostalCode.bind(this));
		shippingPostalCodeElement.addEventListener('input', this.#updatePostalCode.bind(this));
		billingAddressIsShippingAddressElement.addEventListener('input', this.#updatePostalCode.bind(this));

		viCart.addEventListener('change', this.#onCartChange.bind(this));
	}

	#onCountryElementChange(event) {
		const country = event.target.value;

		this.#fetchControllerCountry?.abort();
		this.#fetchControllerCountry = new AbortController();

		this.viCart.updateCountry(country, this.#fetchControllerCountry?.signal);
	}

	#updatePostalCode() {
		/** @type {HTMLFormControlsCollection} */
		const { elements } = this.checkoutFormElement;
		let postalCode;
		if (elements.billingaddress_is_shippingaddress.checked === true) {
			// use postal_code
			postalCode = elements.postal_code.value;
		} else {
			postalCode = elements.shipping_postal_code.value;
		}

		this.#fetchControllerPostalCode?.abort();
		this.#fetchControllerPostalCode = new AbortController();

		this.viCart.updatePostalCode(postalCode, this.#fetchControllerPostalCode?.signal);
	}

	#onCartChange() {
		const text = this.viCart.data?.shipping?.delivery?.textLong ?? null;

		if (!this.deliveryTimeInfoElement.defaultText) {
			this.deliveryTimeInfoElement.defaultText = this.deliveryTimeInfoElement.innerText;
		}

		if (text) {
			this.deliveryTimeInfoElement.innerText = text;
		} else {
			this.deliveryTimeInfoElement.innerText = this.deliveryTimeInfoElement.defaultText;
		}
	}

	onDiscountSubmit(event) {
		event.preventDefault();
		if (event.submitter === this.addDiscountButtonElement) {
			this.#setDiscount();
		} else if (event.submitter === this.removeDiscountButtonElement) {
			this.#removeDiscount();
		}
	}

	#updateDiscount() {
		const { discountCodeElement } = this;
		const discountCodeElementIsEmpty = !discountCodeElement.value.trim() || discountCodeElement.hasAttribute('aria-invalid');
		this.addDiscountButtonElement.toggleAttribute('hidden', !discountCodeElementIsEmpty);
		this.discountCodeElement.toggleAttribute('readonly', !discountCodeElementIsEmpty);
		this.removeDiscountButtonElement.toggleAttribute('hidden', discountCodeElementIsEmpty);
	}

	async #setDiscount() {
		const discountCodeElement = this.checkoutFormElement.querySelector('[name="discount_code"]');
		const { value } = discountCodeElement;

		this.loading = true;
		this.addDiscountButtonElement.toggleAttribute('data-loader', true);
		discountCodeElement.removeAttribute('aria-invalid');
		if (value.trim()) {
			const response = await this.viCart.setDiscount(value);
			if (response.constructor === Error) {
				discountCodeElement.toggleAttribute('aria-invalid', true);
			}
		}

		this.addDiscountButtonElement.toggleAttribute('data-loader', false);
		this.loading = false;

		this.#updateDiscount();
	}

	async #removeDiscount() {
		this.loading = true;
		this.removeDiscountButtonElement.toggleAttribute('data-loader', true);

		this.discountCodeElement.value = '';
		await this.viCart.removeDiscount();

		this.removeDiscountButtonElement.toggleAttribute('data-loader', false);
		this.loading = false;

		this.#updateDiscount();
	}

	removeErrors() {
		this.globalErrorElement.innerText = '';
		this.globalErrorElement.toggleAttribute('hidden', true);
		[...this.checkoutFormElement.querySelectorAll('.a-field__error')].forEach((errorElement) => errorElement.remove());
		[...this.checkoutFormElement.querySelectorAll('.a-input')].forEach((inputElement) => inputElement.removeAttribute('data-error'));
	}

	showGloabalError(text) {
		this.globalErrorElement.innerText = text;
		this.globalErrorElement.removeAttribute('hidden');
	}

	createFieldErrors(details) {
		const { checkoutFormElement } = this;

		Object.keys(details).forEach((key) => {
			const fieldElement = checkoutFormElement.querySelector(`.a-field[data-name="${key}"]`);
			fieldElement.querySelector('.a-input').toggleAttribute('data-error', true);
			const errorElement = document.createElement('div');
			errorElement.classList.add('a-field__error');
			fieldElement.appendChild(errorElement);
			render(html`
				${Object.values(details[key].message).map((message) => html`<p>${message}</p>`)}
			`, errorElement);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	#getClientSecret(paymentMethod) {
		const { protocol, host } = window.location;
		const url = new URL('/api/shop/client-secret', `${protocol}//${host}`);
		url.searchParams.set('payment-method', paymentMethod);
		return fetch(url, {
			headers: {
				'x-language': document.documentElement.lang,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.clientSecret) {
					return data.clientSecret;
				}
				throw data;
			});
	}

	async #handleStripePayment(paymentMethod) {
		const { checkoutFormElement, stripe } = this;
		// Some payment method need additional JavaScript
		if (paymentMethod === 'credit-card-sca' || paymentMethod === 'sepa-debit') {
			let error = null;
			// Optional: Add billing details like name, postal code or city to the stripe request.
			const billingDetails = {
				name: `${checkoutFormElement.given_name.value} ${checkoutFormElement.family_name.value}`,
				email: checkoutFormElement.email.value,
				address: {
					line1: checkoutFormElement.address_line1?.value,
					postal_code: checkoutFormElement.postal_code?.value,
					city: checkoutFormElement.address_level2?.value,
					country: checkoutFormElement.country?.value,
				},
			};

			if (paymentMethod === 'credit-card-sca') {
				const clientSecret = await this.#getClientSecret('card');
				// https://stripe.com/docs/js/payment_intents/confirm_card_payment
				({ error } = await stripe.confirmCardPayment(clientSecret, {
					payment_method: {
						card: this.stripeElements.getElement('card'),
						billing_details: billingDetails,
					},
				}));
			} else if (paymentMethod === 'sepa-debit') {
				const clientSecret = await this.#getClientSecret('sepa_debit');
				({ error } = await stripe.confirmSepaDebitPayment(clientSecret, {
					payment_method: {
						sepa_debit: this.stripeElements.getElement('iban'),
						billing_details: billingDetails,
					},
				}));
			}
			if (error) {
				throw error;
			}
		}

		return null;
	}

	set loading(isLoading = true) {
		this.submitElement.toggleAttribute('data-loader', isLoading);
		this.element.querySelectorAll('button, fieldset, input, select, textarea').forEach((inputElement) => {
			inputElement.toggleAttribute('disabled', isLoading);
		});
	}

	get loading() {
		return this.submitElement.hasAttribute('data-loader');
	}

	async onSubmit(event) {
		try {
			const formElement = this.checkoutFormElement;
			const paymentMethod = formElement.elements.namedItem('paymentMethod')?.value ?? null;
			const formData = new FormData(formElement);

			event.preventDefault();
			this.removeErrors();
			this.loading = true;

			const stripeToken = await this.#handleStripePayment(paymentMethod);
			if (stripeToken) {
				// add stripe token to formData
				formData.append('stripeToken', stripeToken);
			}
			await fetch(formElement.action, {
				body: formData,
				method: 'POST',
				headers: {
					'x-language': document.querySelector('html').lang,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.status === 201) {
						window.location = data.redirect;
					} else if (data.key === 'error.merx.fieldsvalidation') {
						this.createFieldErrors(data.details);
					} else {
						throw data;
					}
				});
		} catch (error) {
			let { message } = error;
			if (error?.details?.message) {
				message = `${message} ${error?.details?.message}`;
			}
			this.showGloabalError(message);
		} finally {
			this.loading = false;
		}
	}
}

if (element) {
	// eslint-disable-next-line no-new
	new OCheckout(element);
}
